import loadPolyfills from '../brighteon/load_polyfills';
import { start } from '../brighteon/common';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import configureStore from '../brighteon/store/configureStore';
import initialState from '../brighteon/initial_state';
import { hydrateStore } from '../brighteon/actions/store';
import { fetchCustomEmojis } from '../brighteon/actions/custom_emojis';
import TopContainer from '../brighteon/features/compose/containers/top_container';

start();

const store = configureStore();

if (initialState) {
  store.dispatch(hydrateStore(initialState));
}

store.dispatch(fetchCustomEmojis());

function loaded() {
  const React = require('react');
  const ReactDOM = require('react-dom');
  const mountNode = document.getElementById('brighteon-navigation');
  const statusesNode = document.getElementById('brighteon-statuses');

  if (statusesNode === null && mountNode !== null) {
    const props = JSON.parse(mountNode.getAttribute('data-props'));
    ReactDOM.render((
      <IntlProvider locale={props.locale}>
        <Provider store={store}>
          <TopContainer />
        </Provider>
      </IntlProvider>), mountNode);
  }
}

function main() {
  const ready = require('../brighteon/ready').default;
  ready(loaded);
}

loadPolyfills().then(main).catch(error => {
  console.error(error);
});
